import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Joyride, { EVENTS } from 'react-joyride';

import {tourCopy, initialData} from '../initial-data';
import Adder from './adder';
import Lane from './lane';
import Styleline from './styleline';

import {onDragEnd} from '../logic';

const apiEndpoint = "https://api.myjson.com/bins/zt9fp";

const Container = styled.div`
  display: flex;
  min-height: 1200px;
`;
const DroppableContainer = styled.div`
  min-width: 1200px;
  margin: 20px 20px 0 20px;
  flex-grow: 1;
  background-color: ${props => (props.isDragging && props.empty ? 'white' : 'inherit')};
  border: ${props => (props.isDragging ? '1px dashed ' + (props.empty ? 'lightgray' : 'darkgray') : 'none')};
  ${props => (props.empty? `
    height: 150px;
    &:before {
      content: '${props.isDragging ? "Drop here" : ""}';
      color: dimgray;
      position: relative;
      left: 50%;
      top: 65px;
    }
  ` : '')}
`;
const ButtonContainer = styled.div`
  position: relative;
  top: -45px;
  width: 200px;
  border-left: 1px solid lightgrey;
  background: white;
  margin-bottom: -50px;
`;
const TourImage = styled.img`
  width: 90%;
  padding-bottom: 10px;
`;
const TourWrapper = styled.div`
  text-align: left;
`;

const TourHeader = styled.h2`
  margin: 5px 0;
  font-weight: normal;
  font-size: 18px;
`;

const TourContent = styled.div`
  font-size: 14px;
  color: #878A8F;
`;

class InnerList extends React.PureComponent {
  render() {
    const {lane, timelineMap, index, barMap, barDragging} = this.props;
    const timelines = lane.timelineIds.map(timelineId => timelineMap[timelineId]);
    return <Lane lane={lane} timelines={timelines} index={index} barMap={barMap} barDragging={barDragging}/>;
  }
}

class App extends React.Component {
  state = initialData;

  componentDidMount() {
    this.setTourSteps(tourCopy);
    fetch(apiEndpoint)
      .then(res => res.json())
      .then(
        (result) => {
          this.setTourSteps(result);
        },
        (error) => {
          // Failed to load, use default copy
          this.setTourSteps(tourCopy);
        }
      )
  }

  // Sets tour step content based on API response
  setTourSteps = copy => {
    const steps = [
      {
        target: '.addlane',
        content: (
            <React.Fragment>
              <TourWrapper>
                <TourImage src={`${process.env.PUBLIC_URL}/addlane.png`} alt="Add Lane"/>
                <TourHeader>{copy[0].header}</TourHeader>
                <TourContent>
                  <p>{copy[0].content[0]}</p>
                  <p>{copy[0].content[1]}</p>
                </TourContent>
              </TourWrapper>
            </React.Fragment>
          ),
        disableBeacon: true,
        placement: 'left-start',
      },
      {
        target: '.addbar',
        content: (
            <React.Fragment>
              <TourWrapper>
                <TourImage src={`${process.env.PUBLIC_URL}/addbar.png`} alt="Add Bar"/>
                <TourHeader>{copy[1].header}</TourHeader>
                <TourContent>
                  <p>{copy[1].content[0]}</p>
                  <p>{copy[1].content[1]}</p>
                </TourContent>
              </TourWrapper>
            </React.Fragment>
          ),
        disableBeacon: true,
        placement: 'left-start',
      },
      {
        target: '.addbar',
        content: (
            <React.Fragment>
              <TourWrapper>
                <TourHeader>{copy[2].header}</TourHeader>
                <TourContent>
                  <p>{copy[2].content[0]}</p>
                </TourContent>
              </TourWrapper>
            </React.Fragment>
          ),
        disableBeacon: true,
        placement: 'left-start',
      },
    ]
    this.setState({
      steps,
      runTour: true,
    });
  }

  // Updates joy ride state
  handleJoyrideCallback = data => {
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
      this.setState({ stepIndex: this.state.stepIndex + 1, runTour: false });
    }
  }

  // Sets vars to reflect dragging state
  onBeforeDragStart = result => {
    const {type} = result;
    
    if (type === 'bar')
      this.setState({barDragging: true});
    
    if (type === 'lane')
      this.setState({laneDragging: true});
  }
  
  onDragEnd = result => {
    this.setState(onDragEnd(this.state, result));
  }

  render() {
    return (
      <div>
        <Joyride
          steps={this.state.steps}
          styles={{options: {primaryColor: '#3473B5'}}}
          hideBackButton={true}
          locale={ { close: 'Got it'} }
          callback={this.handleJoyrideCallback}
          stepIndex={this.state.stepIndex}
          run={this.state.runTour}
        />
        <Styleline/>
        <Container>
          <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onBeforeDragStart}>
            <Droppable
              droppableId="all-lanes"
              type="lane"
            >
              {(provided) =>(
                <DroppableContainer
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDragging={this.state.laneDragging}
                  empty={this.state.nextLaneId === 1}
                >
                  {
                    this.state.laneIds.map((laneId, index) => {
                      const lane = this.state.lanes[laneId];
                      return (
                        <InnerList
                          key={lane.id}
                          lane={lane}
                          timelineMap={this.state.timelines}
                          barMap={this.state.bars}
                          index={index}
                          barDragging={this.state.barDragging}
                        />
                      );
                    })
                  }
                  {provided.placeholder}
                </DroppableContainer>
              )}
            </Droppable>
            <ButtonContainer>
              <div className="addlane">
                <Droppable
                  droppableId="addlane"
                  type="lane"
                  isDropDisabled={true}
                  className="addlane"
                >
                  {(provided) =>(
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Adder id="lane-add" content="Add lane"/>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="addbar">
                <Droppable
                  droppableId="addbar"
                  type="bar"
                  isDropDisabled={true}
                  className="addbar"                                                       
                >
                  {(provided) =>(
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Adder id="bar-add" content="Add bar"/>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </ButtonContainer>
          </DragDropContext>
        </Container>
      </div>
    );
  }
}

export default App;
