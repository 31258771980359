const initialPlaceholders = 8;
const placeholdersPerBar = 2;  

// Returns new state after bars / lanes being moved or added
const onDragEnd = (oldState, result) => {
  const {destination, source, draggableId, type} = result;

  // Reset barDragging
  const state = {
    ...oldState,
    barDragging: false,
    laneDragging: false,
  }

  // Return if item dragged wasn't moved
  if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
    return state;
  }

  // The 'bar-add' element was placed - add a new bar
  if (draggableId === 'bar-add') {
    const timeline = state.timelines[destination.droppableId];
    const newBarIds = Array.from(timeline.barIds);

    const newBar = {
      'id': `bar-${state.nextBarId}`,
      'title': `Roadmap Item ${state.nextBarId}`,
      'isPlaceholder': false,
    };
    newBarIds.splice(destination.index, 0, newBar.id);

    const newTimeline = {
      ...timeline,
      barIds: newBarIds,
      barCount: timeline.barCount + 1,
    };

    let newState = {
      ...state,
      bars: {
        ...state.bars,
        [newBar.id]: newBar,
      },
      timelines: {
        ...state.timelines,
        [newTimeline.id]: newTimeline,
      },
      nextBarId: state.nextBarId + 1,
    };
    newState = updateTimelines(newState, newState.lanes[newTimeline.laneId]);
    newState = updateTimelinePlaceholders(newState, newTimeline);

    if (state.nextBarId === 1 && newState.stepIndex === 2)
      newState.runTour = true;

    return newState;
  }

  // The 'lane-add' element was placed - add a new lane
  if (draggableId === 'lane-add') {
    const newTimeline = {
      id: `timeline-${state.nextTimelineId}`,
      barIds: [],
      barCount: 0,
      laneId: `lane-${state.nextLaneId}`,
    };
    const newLane = {
      'id': `lane-${state.nextLaneId}`,
      'title': 'Lane',
      'timelineIds': [newTimeline.id],
    };
    const newLaneOrder = Array.from(state.laneIds);
    newLaneOrder.splice(destination.index, 0, newLane.id);

    const newState = {
      ...state,
      laneIds: newLaneOrder,
      lanes: {
        ...state.lanes,
        [newLane.id]: newLane,
      },
      timelines: {
        ...state.timelines,
        [newTimeline.id]: newTimeline,
      },
      nextLaneId: state.nextLaneId + 1,
      nextTimelineId: state.nextTimelineId + 1,
    };

    if (state.nextLaneId === 1 && state.stepIndex === 1)
      newState.runTour = true;

    return updateTimelinePlaceholders(newState, newTimeline);
  }

  // A lane was moved
  if (type === 'lane') {
    const newLaneOrder = Array.from(state.laneIds);
    newLaneOrder.splice(source.index, 1);
    newLaneOrder.splice(destination.index, 0, draggableId);

    const newState = {
      ...state,
      laneIds: newLaneOrder
    };
    return newState;
  }

  // A bar was moved
  const start = state.timelines[source.droppableId];
  const finish = state.timelines[destination.droppableId];

  // Moving within the same timeline
  if (start === finish) {
    const newBarIds = Array.from(start.barIds);
    // From source.index, remove 1 element
    newBarIds.splice(source.index, 1);
    // At destination index, add draggableId
    newBarIds.splice(destination.index, 0, draggableId);

    const newTimeline = {
      ...start,
      barIds: newBarIds,
    };

    const newState = {
      ...state,
      timelines: {
        ...state.timelines,
        [newTimeline.id]: newTimeline,
      },
    };

    return updateTimelinePlaceholders(newState, newTimeline);
  }

  // One timeline to another

  // Remove bar from 'start' timeline
  const startBarIds = Array.from(start.barIds);
  startBarIds.splice(source.index, 1);
  let nextPlaceholderId = state.nextPlaceholderId;

  // replace with placeholders
  const bars = {
    ...state.bars
  };
  for (let i = 0; i < placeholdersPerBar; i++) {
    const bar = {
      id: `placeholder-${nextPlaceholderId}`,
      isPlaceholder: true,
    };
    nextPlaceholderId++;
    bars[bar.id] = bar;
    startBarIds.splice(source.index, 0, bar.id);
  }

  const newStartTimeline = {
    ...start,
    barIds: startBarIds,
    barCount: start.barCount - 1,
  };

  // Add bar to 'finish' timeline
  const finishBarIds = Array.from(finish.barIds);
  finishBarIds.splice(destination.index, 0, draggableId);
  const newFinishTimeline = {
    ...finish,
    barIds: finishBarIds,
    barCount: finish.barCount + 1,
  };

  let newState = {
    ...state,
    bars: bars,
    timelines: {
      ...state.timelines,
      [newStartTimeline.id]: newStartTimeline,
      [newFinishTimeline.id]: newFinishTimeline,
    },
    nextPlaceholderId: nextPlaceholderId,
  };

  // If start timeline isn't last empty timeline in lane, remove it
  const empty = newStartTimeline.barCount === 0;
  if (empty) {
    const timelines = newState.lanes[newStartTimeline.laneId].timelineIds.map(timelineId => newState.timelines[timelineId]);
    const emptyCount = timelines.filter(timeline => timeline.barCount === 0).length;
    // Check other timelines in lane
    if (emptyCount > 1) {
      delete newState.timelines[newStartTimeline.id];
      const index = newState.lanes[newStartTimeline.laneId].timelineIds.indexOf(newStartTimeline.id);
      if (index > -1)
        newState.lanes[newStartTimeline.laneId].timelineIds.splice(index, 1);
    }
  }

  newState = updateTimelines(newState, newState.lanes[newFinishTimeline.laneId]);
  newState = updateTimelinePlaceholders(newState, newFinishTimeline);
  return newState;
}

// Updates the 'placeholders' within the timeline.
// Placeholders are a hacky way to get React Beautiful DND to allow for spaces between bars.
const updateTimelinePlaceholders = (state, timeline) => {
  const desiredPlaceholders = timeline.barCount === 0 ? initialPlaceholders : initialPlaceholders - (timeline.barCount - 1) * placeholdersPerBar;
  const placeholderDiff = desiredPlaceholders - (timeline.barIds.length - timeline.barCount);
  if (placeholderDiff === 0)
    return state;

  const bars = {
    ...state.bars
  };

  if (placeholderDiff < 0) {
    // placeHolderDiff placeholders, starting from the end of the list
    let barsRemoved = 0;
    const updatedBarIds = Array.from(timeline.barIds);
    for (let i = updatedBarIds.length - 1; i >= 0 && barsRemoved < Math.abs(placeholderDiff); i--) {
      const bar = bars[updatedBarIds[i]];
      if (bar.isPlaceholder) {
        updatedBarIds.splice(i, 1);
        barsRemoved++;
      }
    }

    const updatedTimeline = {
      ...timeline,
      barIds: updatedBarIds,
    };

    const newState = {
      ...state,
      timelines: {
        ...state.timelines,
        [updatedTimeline.id]: updatedTimeline,
      },
    };

    return newState;
  }

  // Create placeholder bars
  let nextPlaceholderId = state.nextPlaceholderId;

  const updatedBarIds = Array.from(timeline.barIds);
  for (let i = 0; i < placeholderDiff; i++) {
    const bar = {
      id: `placeholder-${nextPlaceholderId}`,
      isPlaceholder: true,
    };
    nextPlaceholderId++;
    bars[bar.id] = bar;
    updatedBarIds.push(bar.id);
  }

  const updatedTimeline = {
    ...timeline,
    barIds: updatedBarIds,
  };

  const newState = {
    ...state,
    bars: bars,
    timelines: {
      ...state.timelines,
      [updatedTimeline.id]: updatedTimeline,
    },
    nextPlaceholderId: nextPlaceholderId,
  }

  return newState;
}

// Updates the timelines within the lanes.
const updateTimelines = (state, lane) => {
  // If all timelines in timeline's lane have bars, add another timeline
  const timelines = lane.timelineIds.map(timelineId => state.timelines[timelineId]);
  const emptyCount = timelines.filter(timeline => timeline.barCount === 0).length;
  if (emptyCount === 0) {
    const newTimeline = {
      id: `timeline-${state.nextTimelineId}`,
      barIds: [],
      barCount: 0,
      laneId: lane.id,
    };
    const newTimelineIds = Array.from(lane.timelineIds);
    newTimelineIds.push(newTimeline.id);

    const updatedLane = {
      ...lane,
      timelineIds: newTimelineIds,
    }

    const newState = {
      ...state,
      timelines: {
        ...state.timelines,
        [newTimeline.id]: newTimeline,
      },
      lanes: {
        ...state.lanes,
        [updatedLane.id]: updatedLane,
      },
      nextTimelineId: state.nextTimelineId + 1
    };

    return updateTimelinePlaceholders(newState, newTimeline);
  }

  // Make sure empty timeline is last on list
  const emptyTimeline = timelines.filter(timeline => timeline.barCount === 0)[0];
  const newTimelineIds = Array.from(lane.timelineIds);
  const index = newTimelineIds.indexOf(emptyTimeline.id);
  if (index > -1 && index !== (newTimelineIds.length - 1)) {
    newTimelineIds.push(newTimelineIds.splice(index, 1)[0]);

    const updatedLane = {
      ...lane,
      timelineIds: newTimelineIds,
    }

    const newState = {
      ...state,
      lanes: {
        ...state.lanes,
       [updatedLane.id]: updatedLane,
      },
    };

    return newState;
  }

  return state;
}

export {onDragEnd};
