// Tour default copy
const tourCopy = [
  {
    header: "We'll start with a lane (DEFAULT!)",
    content: [
      "Lanes represent high level categories, such as teams, product lines, or strategic initiatives. Add a color and description to your lane to communicate valuable details to stakeholders.",
      "Drag and drop a lane to get started.",
    ],
  },
  {
    header: "Awesome! Now let's add a few bars. (DEFAULT!)",
    content: [
      "Bars are your specific initiative. Use them to represent your epics, projects, or tasks, and provide an at a glance view of priority, relationships, and progress.",
      "Drag and drop a bar to get started.",
    ],
  },
  {
    header: "Alright, let's set up a couple more (DEFAULT!)",
    content: ["Once they're added, you can share out your roadmap with your team."],
  },
];

const initialData = {  
  // Data for all of the 'bars' (roadmap items within timelines)
  bars: {
  },
  
  // Data for all timelines (sections within lane).
  // Each timeline keeps track of order of bars
  timelines: {
  },
  
  // Data for all the lanes added
  lanes: {
  },
  // Keeps track of the order of lanes
  laneIds: [],
  
  nextLaneId: 1,
  nextTimelineId: 1,
  nextBarId: 1,
  nextPlaceholderId: 1,
  barDragging: false,
  laneDragging: false,
  
  // tour state
  stepIndex: 0,
  runTour: false,
};

export {tourCopy, initialData};
