import React from 'react';
import styled from 'styled-components';

const items = ["2018", null, "Q1 2019", null, null, "Q2", null, null, "Q3", null, null, "Q4"];

const Container = styled.div`
  padding-top: 30px;
  width: 1200px;
  margin: 0 28px;
  display:flex;
`;
const Item = styled.div`
  width: 110px;
  color: lightgray;
  font-size: 6px;
  &:after {
    content: '${props => (props.content)}';
    font-size: 14px;
    color: darkgray;
    position: relative;
    top: -10px;
    left: -3px;
  };
`;

class InnerList extends React.PureComponent {
  render() {
    return this.props.items.map((content, index) => (
      <Item key={index} content={content}>
        <i className="fas fa-circle"/>
      </Item>
    ));
  }
}

// Renders each of dots (along with labels) within the top timeline
export default class Styleline extends React.Component {
  render() {
    return (
      <Container>
        <InnerList items={items}/>
      </Container>
    );
  }
}