import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import NaturalDragAnimation from 'natural-drag-animation-rbdnd';

const Container = styled.div`
  width: 160px;
  height: 40px;
  background: #E4E6E8;
  border-radius: 5px;
  color: gray;
  font-size: 14px;
  margin: 15px 0 0px 15px;
  padding-top: 7px;
`;
const Arrow = styled.i`
  &:before {
    font-size: 80%;
    position: relative;
    top: ${props => (props.offset)}px;
  }
`;

// Depending on props, renders the element for adding a lane or a bar
export default class Adder extends React.Component {
  render() {
    return (
      <Draggable 
        draggableId={this.props.id}
        index={0}
      >
        {(provided, snapshot) => (
          <NaturalDragAnimation
            style={provided.draggableProps.style}
            snapshot={snapshot}
          >
            {style => (
              <Container
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={style}
              >
                <span className="fa-stack">
                  <Arrow className="fas fa-sort-up fa-stack-1x" offset={-4}/>
                  <i className="fas fa-grip-lines fa-stack-1x"/>
                  <Arrow className="fas fa-sort-down fa-stack-1x" offset={2}/>
                </span>
                {this.props.content}
              </Container>
            )}
          </NaturalDragAnimation>
        )}
      </Draggable>
    );
  }
}
