import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import Bar from './bar';

const Bars = styled.div`
  margin-bottom: 8px;
  padding: 8px;
  background-color: ${props => (props.isDraggingOver ? 'lightgrey' : 'inherit')};
  width: 98%;
  min-height: 30px;
  display: flex;
  border: ${props => (props.isDraggingOver || (!props.hasBars && props.barDragging) ? '1px dashed grey' : 'none')};
`;

class InnerList extends React.PureComponent {
  render() {
    return this.props.bars.map((bar, index) => (
      <Bar key={bar.id} bar={bar} index={index} parentDraggedOver={this.props.parentDraggedOver}/>
    ));
  }
}

// A timeline holds the Bars within a Lane.
// Lanes can have multiple Timelines.
export default class Timeline extends React.Component {
  render() {
    const hasBars = this.props.bars.filter(bar => !bar.isPlaceholder).length > 0;
    return (
      <Droppable droppableId={this.props.timeline.id} direction="horizontal" type="bar">
        {(provided, snapshot) => (
          <Bars
            ref={provided.innerRef}
            {...provided.droppableProps}
            barDragging={this.props.barDragging}
            isDraggingOver={snapshot.isDraggingOver}
            hasBars={hasBars}
          >
            <InnerList bars={this.props.bars} parentDraggedOver={snapshot.isDraggingOver}/>
            {provided.placeholder}
          </Bars>
        )}
      </Droppable>
    );
  }
}
