import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import Timeline from './timeline';

const Container = styled.div`
  margin: 8px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  width: 1220px;
`;
const TitleCaret = styled.i`
  font-size: 14px;
  padding-right: 10px;
`
const TitleContainer = styled.div`
  border-radius: 2px;
  background: #DF6840;
`;
const Title = styled.h3`
  padding: 8px;
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  color: white;
`;
const TimelineList = styled.div`
  padding: 8px;
  flex-grow: 1;
  min-height: 50px;
  position: relative;
`;
const DividerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const Divider = styled.div`
  display: inline-block;
  width: 100.25px;
  height: 100%;
  border-right: 1px solid lightgray;
`

class InnerList extends React.PureComponent {
  render() {
    return this.props.timelines.map((timeline, index) => (
      <Timeline key={timeline.id} timeline={timeline} bars={timeline.barIds.map(barId => this.props.barMap[barId])} index={index} barDragging={this.props.barDragging}/>
    ));
  }
}

class DividerList extends React.PureComponent {
  render() {
    return Array(this.props.items).fill(0).map((content, index) => (
      <Divider key={index} />
    ));
  }
}

// Renders a lane
export default class Lane extends React.Component {
  render() {
    return (
      <Draggable draggableId={this.props.lane.id} index={this.props.index}>
        {(provided) => (
          <Container
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <TitleContainer>
              <Title {...provided.dragHandleProps}>
                <TitleCaret className="fas fa-caret-down"/>
                {this.props.lane.title}
              </Title>
            </TitleContainer>
            <TimelineList
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <DividerContainer className="boom">
                <DividerList items={11}/>
              </DividerContainer>
              <InnerList timelines={this.props.timelines} barMap={this.props.barMap} index={this.props.index} barDragging={this.props.barDragging}/>
              {provided.placeholder}
            </TimelineList>
          </Container>
        )}
      </Draggable>
    );
  }
}
