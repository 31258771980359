import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  color: white;
  background-color: ${props => (
    props.isPlaceholder ? props.parentDraggedOver ? 'lightgray' : 'white' :
    props.isDragging ? 'lightgreen' : '#3473B5')};
  border-radius: 5px;
  padding: 8px;
  margin-right: 8px;
  width: ${props => (props.isPlaceholder ? '96px' : '200px')};
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => (props.isPlaceholder ? '0' : '3')};
`;

// Renders an individual bar within a timeline
export default class Bar extends React.Component {
  render() {
    return (
      <Draggable 
        draggableId={this.props.bar.id}
        index={this.props.index}
        isDragDisabled={this.props.bar.isPlaceholder}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            parentDraggedOver={this.props.parentDraggedOver}
            isPlaceholder={this.props.bar.isPlaceholder}
          >
            {this.props.bar.title}
          </Container>
        )}
      </Draggable>
    );
  }
}
